import { Component } from 'solid-js'
import { Portal } from 'solid-js/web'
import TranslationImportForm from '~/components/forms/translation-import/translation-import'
import { ImportTranslationProps } from '~/components/import-translation/import-translation.interfaces'
import styles from '~/components/import-translation/import-translation.module.scss'
import { translations } from '~/translations'
import CloseIcon from '~/assets/icons/close.svg'

const ImportTranslation: Component<ImportTranslationProps> = ({ open, setOpen, chapterId, pages }) => {
  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      {open && (
        <Portal>
          <div class={styles.wrapper}>
            <div class={styles.container}>
              <button class={styles.close} onClick={onClose}>
                <CloseIcon />
              </button>
              <div class={styles.head}>
                <div class={styles.primaryTitle}>
                  {translations().editor.io.forms.importTranslations.title}
                </div>
                <div class={styles.secondaryTitle}>
                  {translations().editor.io.forms.importTranslations.description} 
                  <a href='/documentation/translation-import' target='blank'>
                    → {translations().editor.io.documentation}
                  </a>
                </div>
              </div>
              <TranslationImportForm
                chapterId={chapterId}
                pages={pages}
              />
            </div>
          </div>
        </Portal>
      )}
    </>
  )
}

export default ImportTranslation
