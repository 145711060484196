const errorMessages: Record<string, string> = {
  'invalid_file': 'invalid_file',
  'empty_file': 'empty_file',
  'duplicated_index': 'duplicated_index',
  'invalid_index': 'invalid_index',
  'invalid_position': 'invalid_position'
}

const getErrorMessage = (errorCode: string) => {
  if (errorCode in errorMessages){
    return errorMessages[errorCode]
  }else{
    return errorMessages['invalid_file']
  }
}

export {
  getErrorMessage
}