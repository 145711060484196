import { Point } from 'fabric'
import { number, object, parse } from 'valibot'
import ImportedTranslation from '~/editor/imported-translation/imported-translation'

const pagePattern = /^#Page\s(?:\d+)\n/gm
const translationEntryPattern = /^#(?<index>\d+)(?:\s)?(?:\(Position:\s(?<position>\d+,\d+)\))?\n(?<translation>.+)/gm

const getPages = (text: string) => {
  const array = text.split(pagePattern)
  if(array.length === 0){
    throw Error('no_pages')
  }else{
    return array.slice(1)
  }
}

const getTranslations = (text: string) => {
  const translations: RegExpExecArray[] = []
  let currentEntry = translationEntryPattern.exec(text)
  while(currentEntry !== null){
    translations.push(currentEntry)
    currentEntry = translationEntryPattern.exec(text)
  }
  return translations
}

const getValidIndex = (index: string | undefined, previousEntries: ImportedTranslation[]) => {
  if(index){
    const integer = parseInt(index)
    if(Number.isInteger(integer)){
      if(previousEntries.find(entry => entry.index === integer)){
        throw new Error('duplicated_index')
      }
      return integer
    }else{
      throw new Error('invalid_index')
    }
  }else{
    throw new Error('invalid_index')
  }
}

const PositionSchema = object({
  x: number(),
  y: number()
})

const getPosition = (position: string | undefined) => {
  if(position){
    const array = position.split(',')
    if(array.length !== 2){
      throw new Error('invalid_position')
    }
    const positionObject = {
      x: parseInt(array[0]),
      y: parseInt(array[1])
    }
    try{
      const point = parse(PositionSchema, positionObject)
      return new Point(point)
    }catch{
      throw new Error('invalid_position')
    }
  }else{
    return null
  }
}

const parseTranslationsFile = (text: string) => {
  const pages = getPages(text)
  const translationsGroupedByPage: ImportedTranslation[][] = []
  pages.forEach((pageContent, index) => {
    const pageIndex = index
    const pageTranslations = getTranslations(pageContent)
    const translationsGroup = pageTranslations.map(entry => {
      const index = getValidIndex(entry.groups?.index, translationsGroupedByPage.flat())
        const position = getPosition(entry.groups?.position)
        const text = entry.groups?.translation ?? ''
        return new ImportedTranslation({
          index,
          pageIndex,
          position,
          text
        })
    })
    translationsGroupedByPage.push(translationsGroup)
  })

  return translationsGroupedByPage
}

export { parseTranslationsFile }