import { Component } from 'solid-js'
import { TranslationImportProps, TranslationImportType, TranslationImportSchema } from '~/components/forms/translation-import/translation-import.interfaces'
import { SubmitHandler, createForm, setResponse, valiForm } from '@modular-forms/solid'
import Button from '~/components/button/button'
import TranslationUploadField from '~/components/translation-upload-field/translation-upload-field'
import { parseTranslationsFile } from '~/services/import/translations'
import { rawInsertTranslations, resetTranslations } from '~/services/database/translations'
import { resetTypesetTexts } from '~/services/database/typeset-texts'
import styles from '~/components/forms/styles/complex-form.module.scss'
import ToggleField from '~/components/fields/toggle-field/toggle-field'
import FormError from '~/components/form-error/form-error'
import { setLoadingState } from '~/services/loading/loading'
import { translations } from '~/translations'

const TranslationImport: Component<TranslationImportProps> = ({ chapterId, pages }) => {
  const [form, { Form, Field }] = createForm<TranslationImportType>({
    validate: valiForm(TranslationImportSchema),
    initialValues: {
      overwriteTranslations: true,
      overwriteTypesetTexts: true
    }
  })

  const onSubmit: SubmitHandler<TranslationImportType> = async (values) => {
    setLoadingState({
      loading: true
    })
    const textFile = await values.textFile.text()
    const translations = parseTranslationsFile(textFile).flat()
    const rows = translations.map(entry => entry.getDBTranslation(chapterId, pages))
    const actions: Promise<null>[] = []

    if(values.overwriteTranslations){
      actions.push(resetTranslations(chapterId))
    }

    if(values.overwriteTypesetTexts){
      actions.push(resetTypesetTexts(chapterId))
    }

    actions.push(rawInsertTranslations(rows))

    Promise.all(actions).catch((error) => {
      setResponse(form, {
        status: 'error',
        message: (error as Error).message
      })
    }).then(() => {
      window.location.reload()
    }).finally(() => {
      setLoadingState({
        loading: false
      })
    })
  }

  return (
    <Form onSubmit={onSubmit}>
      <Field name="textFile" type="File">
        {(field, props) => (
          <TranslationUploadField
            label={translations().editor.io.forms.importTranslations.translationsFile}
            {...field}
            {...props}
          />
        )}
      </Field>

      <div class={styles.fieldColumnGroup}>
        <div class={styles.fieldColumn}>
          <Field name='overwriteTranslations' type='boolean'>
            {(field, props) => (
              <ToggleField
                label={translations().editor.io.forms.importTranslations.overwriteTranslations}
                {...field}
                {...props}
              />
            )}
          </Field>
        </div>
        <div class={styles.fieldColumn}>
          <Field name='overwriteTypesetTexts' type='boolean'>
            {(field, props) => (
              <ToggleField
                label={translations().editor.io.forms.importTranslations.overwriteTypesets}
                {...field}
                {...props}
              />
            )}
          </Field>
        </div>
      </div>

      <div class={styles.footer}>
        <FormError response={form.response} />

        <Button
          type='submit'
          style='lagoon'
          isLoading={form.submitting}
        >
          {translations().general.actions.import}
        </Button>
      </div>
    </Form>
  )
}

export default TranslationImport