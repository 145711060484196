import { FieldElement } from '@modular-forms/solid'
import { Component, For, Show, createSignal } from 'solid-js'
import { getErrorMessage } from '~/components/translation-upload-field/error-messages'
import { TranslationUploadFieldProps } from '~/components/translation-upload-field/translation-upload-field.interfaces'
import styles from '~/components/translation-upload-field/translation-upload-field.module.scss'
import ImportedTranslation from '~/editor/imported-translation/imported-translation'
import { parseTranslationsFile } from '~/services/import/translations'
import CloudIcon from '~/assets/icons/cloud-upload.svg'
import { translations } from '~/translations'

const TranslationUploadField: Component<TranslationUploadFieldProps> = ({ name, label, error, value, onChange, ...props }) => {
  const classList = () => ({
    [`${styles.container}`]: true,
    [`${styles.hasError}`]: Boolean(error),
  })
  const [filename, setFilename] = createSignal<string>()
  const [errorMessage, setErrorMessage] = createSignal<string>()
  const [importedTranslations, setImportedTranslations] = createSignal<ImportedTranslation[][]>()

  const onFileChange = async (event: Event & { currentTarget: FieldElement; target: Element; }) => {
    const file = value
    if (file) {
      setFilename(file.name)
      try{
        if (file.type === 'text/plain') {
          const text = await file.text()
          const importedTranslations = parseTranslationsFile(text)
          if (importedTranslations.length === 0) {
            throw new Error('empty_file')
          }else{
            setErrorMessage(undefined)
          }
          setImportedTranslations(importedTranslations)
        } else {
          throw new Error('invalid_file')
        }
      }catch(error){
        const message = getErrorMessage((error as Error).message)
        setErrorMessage(message)
        setImportedTranslations(undefined)
      }
    }
    onChange(event)
  }

  return (
    <div classList={classList()}>
      <label
        for={name}
        class={styles.label}
      >
        {label}
      </label>
      <div class={styles.fields}>
        <div class={styles.dropzone}>
          <Show when={filename()} fallback={(
            <div class={styles.emptyState}>
              <CloudIcon class={styles.icon} />
              <span>{translations().general.actions.import}</span>
            </div>
          )}>
            <div class={styles.currentFile}>
              <CloudIcon class={styles.icon} />
              <span>{filename()}</span>
            </div>
          </Show>
          <input
            class={styles.fileUploadInput}
            type='file'
            {...props}
            onChange={onFileChange}
          />
        </div>
        <div class={styles.preview}>
          <Show when={importedTranslations()} fallback={(
            <Show when={errorMessage()} fallback={(
              <div class={styles.emptyState}>
                <span>{translations().editor.io.preview}</span>
              </div>
            )}>
              <div class={styles.errorMessage}>
                <span>{errorMessage()}</span>
              </div>
            </Show>
          )}>
            <For each={importedTranslations()}>
              {(page, index) => (
                <div class={styles.pageGroup}>
                  <div class={styles.pageHead}>{translations().editor.page} {index()+1}</div>
                  <For each={page}>
                    {entry => (
                      <div class={styles.translationEntry}>
                        <div class={styles.index}>{entry.index}</div>
                        <div class={styles.text}>{entry.text}</div>
                      </div>
                    )}
                  </For>
                </div>
              )}
            </For>
          </Show>
        </div>
      </div>
      {error && (
        <div class={styles.error}>
          {error}
        </div>
      )}
    </div>
  )
}

export default TranslationUploadField
