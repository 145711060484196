import { InferOutput, boolean, instance, object } from 'valibot'
import Document from '~/editor/document/document'
import { Database } from '~/types/supabase'

export interface TranslationImportProps{
  chapterId: Database['public']['Tables']['chapters']['Row']['id']
  pages: Document['pages']
}

export const TranslationImportSchema = object({
  textFile: instance(File),
  overwriteTranslations: boolean(),
  overwriteTypesetTexts: boolean()
})

export type TranslationImportType = InferOutput<typeof TranslationImportSchema>