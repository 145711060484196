import { Point } from 'fabric'
import { v4 as uuidv4 } from 'uuid'
import Document from '~/editor/document/document'
import { ImportedTranslationProps } from '~/editor/imported-translation/imported-translation.interfaces'
import { pointToJson } from '~/helpers/database-conversions'
import { Database } from '~/types/supabase'

type Translation = Database['public']['Tables']['translations']['Insert']
type ChapterId = Database['public']['Tables']['chapters']['Row']['id']

class ImportedTranslation{
  index: number
  pageIndex: number
  position: Point | null
  text: string
  
  constructor({ index, pageIndex, position, text }: ImportedTranslationProps){
    this.index = index
    this.pageIndex = pageIndex
    this.position = position
    this.text = text
  }

  getDBTranslation(chapterId: ChapterId, pages: Document['pages']){
    const correspondingPage = pages.find(page => page.index === this.pageIndex)
    if (correspondingPage){
      let startPoint: Point | null = null
      let endPoint: Point | null = null
      
      if (this.position){
        const defaultBoxSize = 0.25
        startPoint = this.position.divide(new Point(100, 100))
        endPoint = startPoint.clone()
        const boxH = defaultBoxSize * correspondingPage.image.getScaledWidth() / correspondingPage.image.getScaledHeight()
        endPoint.setXY(endPoint.x + defaultBoxSize / 2, endPoint.y + boxH / 2)
      }
      
      const row: Translation = {
        created_at: new Date().toUTCString(),
        chapter_id: chapterId,
        entry_id: uuidv4(),
        is_correction: false,
        is_onomatopoeia: false,
        start_point: startPoint ? pointToJson(startPoint) : startPoint,
        end_point: endPoint ? pointToJson(endPoint) : endPoint,
        original_text: '',
        translated_text: this.text,
        page_id: correspondingPage.id
      }
      return row
    }else{
      console.error('Could not find correponding page for Translation', this)
      throw new Error('Could not find correponding page for Translation')
    }
  }
}

export default ImportedTranslation
